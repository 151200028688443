var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import * as THREE from "three";
import { SceneSubject } from "./sceneSubject";
var Bigscreen = /** @class */ (function (_super) {
    __extends(Bigscreen, _super);
    function Bigscreen() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    Bigscreen.prototype.onCreate = function () {
        // Plane geometry
        var geometry = new THREE.PlaneBufferGeometry(11, 11);
        var loadingManager = new THREE.LoadingManager();
        var textureLoader = new THREE.TextureLoader(loadingManager);
        var img = textureLoader.load('/textures/bigscreen/portfolio.jpg');
        var bigscreenMaterial = new THREE.MeshStandardMaterial({
            map: img,
            emissiveMap: img,
            emissive: 0xffffff,
            emissiveIntensity: 1,
            roughness: 1,
            fog: false
        });
        var bigscreen = new THREE.Mesh(geometry, bigscreenMaterial);
        bigscreen.position.y = 5.4;
        bigscreen.position.z = -5;
        bigscreen.position.x = 1.2;
        this.scene.add(bigscreen);
    };
    Bigscreen.prototype.update = function () {
    };
    ;
    return Bigscreen;
}(SceneSubject));
export { Bigscreen };
