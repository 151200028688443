var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import * as THREE from "three";
import { SceneSubject } from "./sceneSubject";
import { Reflector } from 'three/examples/jsm/objects/Reflector';
import metallic from "../materials/metallic";
var ReflectiveGroundPlane = /** @class */ (function (_super) {
    __extends(ReflectiveGroundPlane, _super);
    function ReflectiveGroundPlane() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    ReflectiveGroundPlane.prototype.onCreate = function () {
        // Plane geometry
        var geometry = new THREE.PlaneBufferGeometry(15, 30);
        var groundMirror = new Reflector(geometry, {
            clipBias: 0.003,
            textureWidth: window.innerWidth * window.devicePixelRatio,
            textureHeight: window.innerHeight * window.devicePixelRatio,
            color: 0x666666
        });
        groundMirror.rotation.x = -Math.PI / 2;
        groundMirror.position.y = -0.1;
        var groundPlane = new THREE.Mesh(geometry, metallic);
        groundPlane.rotation.x = -Math.PI / 2;
        groundPlane.position.y = 0;
        this.scene.add(groundPlane);
        this.scene.add(groundMirror);
    };
    ReflectiveGroundPlane.prototype.update = function () {
    };
    ;
    return ReflectiveGroundPlane;
}(SceneSubject));
export { ReflectiveGroundPlane };
