import './style.css';
import * as THREE from 'three';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls.js';
import * as dat from 'dat.gui';
import { ReflectiveGroundPlane } from './sceneSubjects/reflectiveGroundPlane';
import { Bigscreen } from './sceneSubjects/bigscreen';
import { CyberpunkCity } from './sceneSubjects/cyberpunkCity';
import { EffectComposer } from 'three/examples/jsm/postprocessing/EffectComposer.js';
import { RenderPass } from 'three/examples/jsm/postprocessing/RenderPass.js';
var SceneManager = /** @class */ (function () {
    function SceneManager() {
        this.canvas = document.querySelector('canvas.sb-webgl');
        this.sizes = {
            width: window.innerWidth,
            height: window.innerHeight
        };
        this.init();
    }
    SceneManager.getInstance = function () {
        if (!SceneManager.instance) {
            this.instance = new SceneManager();
        }
        return SceneManager.instance;
    };
    SceneManager.prototype.init = function () {
        var _this = this;
        this.gui = new dat.GUI();
        // Scene
        this.scene = new THREE.Scene();
        var loader = new THREE.TextureLoader();
        var bgTexture = loader.load('/textures/skybox.jpg');
        this.scene.background = bgTexture;
        this.scene.fog = new THREE.Fog('black', 2, 25);
        /**
         * Camera
         */
        // Base camera
        this.camera = new THREE.PerspectiveCamera(75, this.sizes.width / this.sizes.height, 0.1, 100);
        this.camera.layers.enable(1);
        this.camera.position.x = 1;
        this.camera.position.y = 1;
        this.camera.position.z = 2;
        this.scene.add(this.camera);
        // Controls
        this.controls = new OrbitControls(this.camera, this.canvas);
        this.controls.enableDamping = true;
        /**
         * Renderer
         */
        this.renderer = new THREE.WebGLRenderer({
            canvas: this.canvas,
        });
        this.renderer.shadowMap.enabled = true;
        this.renderer.setSize(this.sizes.width, this.sizes.height);
        this.renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2));
        window.addEventListener('resize', function () { _this.onWindowResize(); });
        // Create composer
        this.initPostProcessing();
    };
    SceneManager.prototype.initPostProcessing = function () {
        // Make composer
        this.composer = new EffectComposer(this.renderer);
        var renderPass = new RenderPass(this.scene, this.camera);
        this.composer.addPass(renderPass);
    };
    SceneManager.prototype.setupScene = function () {
        this.sceneSubjects = [];
        this.sceneSubjects.push(new ReflectiveGroundPlane(this.scene));
        this.sceneSubjects.push(new CyberpunkCity(this.scene));
        this.sceneSubjects.push(new Bigscreen(this.scene));
        //Point light
        var pointLight = new THREE.PointLight(0xffffff, 0.3, 30);
        pointLight.translateY(5);
        pointLight.translateZ(-5);
        pointLight.translateX(2);
        this.scene.add(pointLight);
        var sphereSize = 1;
        //const pointLightHelper = new THREE.PointLightHelper(pointLight, sphereSize);
        //this.scene.add(pointLightHelper);
    };
    SceneManager.prototype.getRenderer = function () {
        return this.renderer;
    };
    SceneManager.prototype.onWindowResize = function () {
        // Update sizes
        this.sizes.width = window.innerWidth;
        this.sizes.height = window.innerHeight;
        // Update camera
        this.camera.aspect = this.sizes.width / this.sizes.height;
        this.camera.updateProjectionMatrix();
        // Update renderer
        this.renderer.setSize(this.sizes.width, this.sizes.height);
        this.renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2));
    };
    SceneManager.prototype.update = function () {
        var _this = this;
        /**
         * Animate
         */
        var clock = new THREE.Clock();
        var tick = function () {
            var elapsedTime = clock.getElapsedTime();
            // Update controls
            _this.controls.update();
            for (var _i = 0, _a = _this.sceneSubjects; _i < _a.length; _i++) {
                var subject = _a[_i];
                subject.update(elapsedTime, null);
            }
            // Render
            //this.renderer.render(this.scene, this.camera)
            _this.composer.render();
            // Call tick again on the next frame
            window.requestAnimationFrame(tick);
        };
        tick();
    };
    return SceneManager;
}());
export { SceneManager };
