import * as THREE from 'three';
var loadingManager = new THREE.LoadingManager();
var textureLoader = new THREE.TextureLoader(loadingManager);
// Load textures
var colorTexture = textureLoader.load('/textures/metal/Metal_scratched_007_basecolor.jpg');
var heightTexture = textureLoader.load('/textures/metal/Metal_scratched_007_height.jpg');
var normalTexture = textureLoader.load('/textures/metal/Metal_scratched_007_normal.jpg');
var ambientOcclusionTexture = textureLoader.load('/textures/metal/Metal_scratched_007_ambientOcclusion.jpg');
var metalnessTexture = textureLoader.load('/textures/metal/Metal_scratched_007_metallic.jpg');
var roughnessTexture = textureLoader.load('/textures/metal/Metal_scratched_007_roughness.jpg');
colorTexture.wrapS = THREE.RepeatWrapping;
colorTexture.wrapT = THREE.RepeatWrapping;
export default new THREE.MeshStandardMaterial({
    aoMap: ambientOcclusionTexture,
    aoMapIntensity: 1,
    map: colorTexture,
    metalnessMap: metalnessTexture,
    metalness: 1,
    normalMap: normalTexture,
    roughnessMap: roughnessTexture,
    roughness: 1,
    displacementMap: heightTexture,
    opacity: 0.6,
    transparent: true
});
