var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __spreadArrays = (this && this.__spreadArrays) || function () {
    for (var s = 0, i = 0, il = arguments.length; i < il; i++) s += arguments[i].length;
    for (var r = Array(s), k = 0, i = 0; i < il; i++)
        for (var a = arguments[i], j = 0, jl = a.length; j < jl; j++, k++)
            r[k] = a[j];
    return r;
};
import * as THREE from "three";
import { SceneSubject } from "./sceneSubject";
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader.js';
import { DRACOLoader } from 'three/examples/jsm/loaders/DRACOLoader.js';
var CyberpunkCity = /** @class */ (function (_super) {
    __extends(CyberpunkCity, _super);
    function CyberpunkCity() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    CyberpunkCity.prototype.onCreate = function () {
        var _this = this;
        var gltfLoader = new GLTFLoader();
        var dracoLoader = new DRACOLoader();
        dracoLoader.setDecoderPath('/draco/');
        gltfLoader.setDRACOLoader(dracoLoader);
        gltfLoader.load('/models/cyberpunk_scene_draco.glb', function (gltf) {
            var cyberpunkCity = new THREE.Group();
            var children = __spreadArrays(gltf.scene.children);
            for (var _i = 0, children_1 = children; _i < children_1.length; _i++) {
                var child = children_1[_i];
                child.receiveShadow = true;
                cyberpunkCity.add(child);
            }
            _this.scene.add(cyberpunkCity);
        });
    };
    CyberpunkCity.prototype.update = function () {
    };
    ;
    return CyberpunkCity;
}(SceneSubject));
export { CyberpunkCity };
